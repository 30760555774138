<template>
  <v-container>
    <v-skeleton-loader
      type="table"
      v-if="!data_loaded"
      class="mt-10"
    ></v-skeleton-loader>
    <!--/ sekeletion -->

    <v-card elevation="0" class="mt-10" v-if="data_loaded">
      <v-card-title class="font-weight-bold">
        <h4>الطلبات</h4>

      </v-card-title>
      <v-data-table
        :footer-props="$store.state.footer_props"
        :items="orders"
        :headers="headers"
        :mobile-breakpoint="0"
      >
      <template v-slot:[`item.customer.name`] ="{item}">
        <v-btn text  color="blue"  :to="`/customerOrders?id=${item.customer.id}`">
          {{item.customer.name}}
        </v-btn>
      </template>
      <template v-slot:[`item.status`]="{item}">
        <v-chip color="#e58200" x-small v-if="item.status == 0" class='white--text' >
            جديد
        </v-chip>
        <v-chip  x-small color="#644ae1" v-if="item.status == 1" class='white--text'>
            قيد التحضير
        </v-chip>
        <v-chip color="#202020" v-if="item.status == 2" x-small class='white--text'>
            تم التجهيز
        </v-chip>
        <v-chip color="success" v-if="item.status == 3" x-small >
          تم التجهيز
        </v-chip>
        <v-chip color="red" v-if="item.status == 4" class='white--text'  x-small >
            ملغي
        </v-chip>
      </template>
      <template v-slot:[`item.category_name`]="{ item }">
        {{item.category_name[0].name}}
        </template>
        <!--/ logo -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn @click="show_order(item)"  small icon>
            <v-icon small>visibility</v-icon>
          </v-btn>
        </template>
        <!--/ actions -->
      </v-data-table>
      <!--/ datatable -->
    </v-card>
    <!--/ card -->

      <Order @close_dialog="dialog = false" :dialog="dialog" :order="item"></Order>


    <v-snackbar color="success" left v-model="snackbar_success">
      <p>تم حذف المحل</p>
      <template v-slot:action>
        <v-icon color="white" class="ms-3">check_circle</v-icon>
      </template>
    </v-snackbar>
  </v-container>
  <!--/ container -->
</template>

<script>
  import Order from "@/components/orders/order.vue";
export default {
  name: "employees",

  data() {
    return {
      item:{
        created_at:null,
        customer:{},
        id:"",
        notes:null,
        products:[],
        status:[]
      },
      dialog:false,
      snackbar_success: false,
      delete_dialog: false,
      delete_loader: false,
      delete_id: null,
      data_loaded: false,
      orders: [],
      headers: [
        {
          value: "id",
          text: " رقم الطلب",
          align: "center",
        },
        {
          value: "status",
          text: "حالة الطلب",
          align: "center",
        },
        {
          value: "customer.name",
          text: "الزبون",
          align: "center",
        },
        {
          value: "customer.phone",
          text: "رقم الزبون",
          align: "center",
        },
        {
          value: "created_at",
          text: "تاريخ الطلب",
          align: "center",
        },
        {
          value: "actions",
          text: "اجراءات",
          align: "center",
        },
      ],
      employee_index: -1,
      employee_data: null,
      attachments: [],
      dialog: false,
      files: [],
    };
  },
  components:{
    Order
  },
  watch:{

  },
  methods: {
    get_orders() {
      this.$store
        .dispatch("public__request", {
          config: {
            url:"all_orders",
            method: "get",
          },
        })
        .then((res) => {
          this.data_loaded = true;
          this.orders = Object.assign([], res.data.data);
        })
    },
    show_order(item) {
      this.item = item;
      this.dialog = true;
    },

  },
  mounted() {
    this.get_orders();
  },
};
</script>
