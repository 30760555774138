<template>
  <v-dialog
    @input="dialog_closed($event)"
    scrollable
    max-width="400"
    :value="dialog"
  >
    <v-card>
      <v-card-title>
        <div>
          <h5>{{ order.customer.name }}</h5>
          <p class="caption">{{ order.customer.phone }}</p>
        </div>
        <v-spacer></v-spacer>
        <div class="text-end d-flex">
          <div class="ml-4">
            <h5>#{{ order.id }}</h5>
            <p class="caption">{{ order.created_at }}</p>
          </div>
          <v-btn small @click="$emit('close_dialog')" fab>
            <v-icon>close</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <!--/card title -->
      <v-card-text class="pt-5">
        <v-alert
          v-if="order.notes"
          color="#feebcb"
          class="text-center body-2"
          dense
          >{{ order.notes }}</v-alert
        >
        <ul
          class="font-weight-bold"
          style="list-style: none"
          v-for="(product, p) in order.products"
          :key="p"
        >
          <li class="d-flex align-center justify-space-between mb-2">
            <div>
              <v-chip
                :color="product.quantity == 1 ? 'grey' : 'yellow'"
                label
                class="px-1 ml-1 lighten-3"
                small
                >x{{ product.quantity }}</v-chip
              >
              {{ product.product_name[0].name }}
            </div>
            <div class="mr-2">({{ product.product_price }} ₪)</div>
            <div>{{ total_item[p] }} ₪</div>
          </li>
          <li>
            <div
              class="mb-2"
              v-for="(free_add, f) in product.free_additions"
              :key="f"
            >
              {{ free_add.name }}
              <span v-if="free_add.used">({{ free_add.price }} ₪)</span>
              <span class="error--text" v-if="!free_add.used">(بدون)</span>
            </div>
          </li>

          <li>
            <div class="mb-2" v-for="(add, f) in product.additions" :key="f">
              {{ add.name }}
              <span>({{ add.price }} ₪)</span>
            </div>
          </li>
        </ul>
        <!--/ ul -->

        <div class="d-flex grey lighten-3 mb-4 py-2 px-3 justify-space-between">
          <span>الاجمالي</span>
          <h3>{{ total_order }} ₪</h3>
        </div>
        <!--/ total -->
        <div class="d-flex justify-space-between">
          <v-btn width="45%" height="40" class="white--text" color="#eeb300">
            <v-icon>print</v-icon>
          </v-btn>
        </div>
      </v-card-text>
      <!--/card text -->
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Order",
  props: ["dialog", "order"],
  data() {
    return {};
  },
  computed: {
    total_item() {
      let totals = [];
      this.order.products.forEach((e) => {
        let price = e.product_price;
        e.additions.forEach((s) => {
          price += s.price;
        });
        totals.push(price * e.quantity);
      });
      return totals;
    },
    total_order() {
      let total = 0;
      this.total_item.forEach((e) => {
        total += e;
      });
      return total;
    },
  },
  methods: {
    dialog_closed(ev) {
      if (!ev) {
        console.log("as");
        this.$emit("close_dialog");
      }
    },
  },
};
</script>

<style scoped lang="scss">
ul {
  padding-bottom: 15px !important;
  &:first-of-type {
    margin-top: 25px;
  }
  &:not(:last-of-type) {
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd;
  }

  // padding-bottom:15px;
}
</style>
